/**
 * // Prevents page refresh by utilizing the XHR object
 **/
function ajax_file(fichier) {
    if (window.XMLHttpRequest) // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject) // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else
        return (false);
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return (xhr_object.responseText);
    else return (false);
}

/**
 * // Create the instantiation of the XHR object that the browser imports
 **/
function getXMLHttpRequest() {

    var xhr = null;

    if (window.XMLHttpRequest || window.ActiveXObject) {
        if (window.ActiveXObject) {
            var tableau_xhr_activeX = [
                'Msxml2.XMLHTTP.6.0',
                'Msxml2.XMLHTTP.3.0',
                'Msxml2.XMLHTTP',
                'Microsoft.XMLHTTP'
            ];

            for (var value in tableau_xhr_activeX) {
                try {
                    xhr = new ActiveXObject(tableau_xhr_activeX[value]);
                    break;
                } catch (error) {
                }
            }

        } else {
            xhr = new XMLHttpRequest();
        }

    } else {
        return null;
    }
    return xhr;
}

/**
 * Create cookie
 **/
function createCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    } else {
        var expires = "";
    }

    document.cookie = name + "=" + value + expires + "; path=/";
}

/**
 * Read cookie
 **/
function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
}

/**
 * Function push into DataLayer
 **/

function pushIntoDatalayer(data) {
    if (typeof (data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof (data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}

// La fonction isInViewport sert à contrôler si le bouton apparaît à l'écran. Si c'est le cas, elle retourne true et le clic est simulé, sinon non. Cette fonction est appelée dans la condition du clic lors du défilement.

function isInViewport(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

/**
 * Refresh available quantity
 **/
function getAvailableQuantity(id, qty, from, event, itm_rr_id, RefreshQuantity = true) {
    let addProductToCart = false;
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    let form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);
    let is_achat_express = $('#is_achat_express_v2').val() == 1;
    let elem = is_achat_express ? '_' + id + itm_rr_id : '';
    let productId = form.getValue('produit_id');
    let displayQuantity = ($('.display_quantity').length > 0 && $('.display_quantity').val() === '1');
    let productColor = form.getValue('idCouleur_' + id + itm_rr_id);

    if (typeof (productColor) == 'undefined') {
        productColor = form.getValue('idCouleur');
    }

    let val_color = form.getValue(productColor);
    if (val_color === '0') {
        val_color = 'none';
    }

    let productSize = form.getValue('idTaille');
    let val_size = form.getValue(productSize);
    if (val_size === '0') {
        val_size = 'none';
    }

    if (productId !== '' && val_color !== '' && val_size !== '') {
        let data = {
            idprod: productId,
            idcouleur: val_color,
            idtaille: val_size,
            isGlobalEActive: isGlobalEActive,
            json: '1'
        };

        if (from === 'basket') {
            data.suff = productId;
        }

        $.ajax({
            url: path_relative_root + 'ajax_liste_qte.php',
            type: 'get',
            async: false, // Should be async but would then need a loader so...
            dataType: 'json',
            data: data,
            success: function (res) {

                // Refresh bloc quantity depending on the size selected
                if (RefreshQuantity && displayQuantity && typeof res.content !== "undefined") {

                    let qteElem = $("#liste_qte");

                    if (qteElem.length) {
                        qteElem.html(res.content);

                        if (!res.inventory.enableAddToCart) {
                            qteElem.addClass('cache');
                        } else {
                            qteElem.removeClass('cache');
                        }
                    }
                }

                if (is_achat_express) {

                    if (!res.inventory.enableAddToCart) {

                        // Display bloc alert stock
                        $("#bloc_add_alert" + elem).show();
                        $("#bloc_size_not_available" + elem).show();

                        // Hide bloc ajout panier
                        $("#prod_info" + elem + " .productSizeFieldset").hide();
                    } else {
                        addProductToCart = true;

                        // Hide bloc alert stock
                        $("#bloc_add_alert" + elem).hide();
                        $("#bloc_size_not_available" + elem).hide();

                        // Display bloc ajout panier
                        $("#prod_info" + elem + " .rollover_left").show();
                        $("#prod_info" + elem + " .bloc_add_color").show();
                        $("#prod_info" + elem + " .productSizeFieldset").show();
                    }
                } else { //Fiche produit

                    // Reset bloc alert stock
                    $(".product_info_wrapper .bloc_add_alert_form").hide();
                    $(".product_info_wrapper .bloc_add_alert_confirmation").hide();
                    $(".product_info_wrapper .bloc_add_alert_erreur").hide();

                    if (!res.inventory.enableAddToCart) {
                        $("#bloc_add_alert").removeClass("cache");
                        $("#btn_alert_stock").show();
                        $(".product_info_wrapper #size_is_not_available").show();
                        $("#bloc_add_basket").addClass("inactiv").hide();
                        $('#btn_add_cart').prop('disabled', true);
                    } else {
                        $("#bloc_add_alert").addClass("cache");
                        $("#btn_alert_stock").hide();
                        $(".product_info_wrapper #size_is_not_available").hide();
                        $("#bloc_add_basket").removeClass("inactiv").show();
                        $('#btn_add_cart').prop('disabled', false);
                    }
                }

                // Custom quantity selector update for FP
                if ($('#product_stock_counter').length && parseInt(res.inventory.stockQuantity)) {
                    $('#product_stock_counter').val(parseInt(res.inventory.stockQuantity));
                }
            }
        });

        if (addProductToCart) {
            waitingAddToBasket(event, form, from, itm_rr_id);
        }
    }
}

function closeAlerteStock(elem) {
    // Hide bloc alert stock
    $("#ligne_pointure_" + elem).show();
    $("#ligne_pointure_" + elem + " input").prop('checked', false);
    $("#ligne_pointure_" + elem + " .form_itm ").removeClass('selected');
    $("#bloc_add_alert_" + elem).hide();
}

/**
 * Remove product from cart header rollover
 * @param {*} basket_id
 * @param {*} prod_id
 * @param {*} qte
 */
function ajax_RemoveBasketProd(basket_id, prod_id, qte) {

    $.ajax({
        type: 'post',
        data: {
            basket_id: basket_id,
            prod_id: prod_id,
            qte: qte
        },
        url: path_relative_root + 'ajax_removeBasketProd.php',
        success: function (res) {

            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            $('#cart_top').html(res.datas.html);
        }
    });
}

$(window).on("add_to_cart_success", function (data) {
    // Update top cart
    var response_basket = ajax_file(path_relative_root + 'ajax_show_basket.php');

    $("#cart_top").html(response_basket);

    // add class to trigger mouseenter event
    setTimeout(function () {
        $('#cart_top').addClass('actif');
    }, 300);
    // remove class to trigger mouseleave event after 2 seconds (example)
    setTimeout(function () {
        $('#cart_top').removeClass('actif');
    }, 3000);
});

$(window).on("add_to_cart_error", function (data) {

    if (loader) {
        loader.parent().removeClass('loading');
    } else {
        $("#bloc_btn_active" + id).attr('class', '');
        $("#bloc_btn_loader" + id).attr('class', 'f_right cache');
    }
});

/**
 * Reload product from ref
 **/
function loadProduct(new_id, type, page, itm_rr_id) {

    let old_id = this.getValue('produit_id');
    let col_id = this.getValue(this.getValue('idCouleur'));
    let sze_id = this.getValue(this.getValue('idTaille'));
    let act = this.getValue('act');
    let qte_itm = 1;
    if (this.elements.namedItem('qteProd')) {
        qte_itm = this.elements.namedItem('qteProd').value;
    }

    let is_wishlist = $('#wishlist').length > 0;
    if ($('#tunnel_right_col').length > 0) {
        is_wishlist = false;
    }
    let is_achat_express = $('#is_achat_express_v2').val() == 1;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    let cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
    let isSearchPage = $('.dynasearchwrapper').length > 0;
    let isCategSuper = (this.getValue('isCategSuper') && !isSearchPage) ? this.getValue('isCategSuper') : 0;
    let isAssocProduct = this.getValue('isAssocProduct') ? this.getValue('isAssocProduct') : 0;
    let pageType = (isAssocProduct == '1' ? 'assoc' : ((page !== undefined && page !== '') ? page : 'product'));

    let data = {
        produit_id: old_id,
        produit_principal: old_id,
        couleurProd: col_id,
        tailleProd: sze_id,
        act: act,
        qteProd: qte_itm,
        produit_id_new: new_id,
        type: type,
        type_page: pageType,
        is_achat_express: is_wishlist ? 1 : 0,
        is_achat_express_v2: is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod: cpt_prod - 1,
        isCategSuper: isCategSuper
    };

    if (is_wishlist) {
        data.wishlist = true;
    }

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_get_product_regroup_ref.php',
        data: data,
        success: function (res) {

            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            if (res.success === 'ok') {
                var datas = res.datas;
                if (data.type_page === 'product' || data.type_page === 'assoc') {
                    var $html_main = $(stripCombo(datas.html_main));
                    var main_wrapper = $('.product_main_wrapper', $html_main);
                    var zoom_wrapper = $('.zoom_main_wrapper', $html_main);
                    var feats_wrapper = $('.product_info_wrapper', $html_main);

                    if (data.is_achat_express_v2) {
                        // Photo et information
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_', '-') : '');
                        $('#itm-' + itm_id).replaceWith($html_main);

                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input.size_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    } else {
                        var association_product_wrapper = $('.product_association', $html_main);
                        var association_theme_wrapper = $('.product_association_theme', $html_main);
                        var cms_page_wrapper = $('.product_cms_bloc', $html_main);
                        var color = $("#ligne_couleur").html();

                        // Photo et information
                        $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());

                        // Zoombox
                        $('.zoom_main_wrapper', '#site_global_wrap').html(zoom_wrapper.html());

                        // Description compo, livraison, guide tailles
                        $('.product_info_wrapper', '#site_global_wrap').html(feats_wrapper.html());

                        // Product association
                        $('.product_association', '#site_global_wrap').html(association_product_wrapper.html());

                        // Theme association
                        $('.product_association_theme', '#site_global_wrap').html(association_theme_wrapper.html());

                        // Page ADV modules
                        $('.product_cms_bloc', '#site_global_wrap').html(cms_page_wrapper.html());

                        $("#ligne_couleur").html(color);
                        $('input.color').prop('checked', false);
                        $('input.size').prop('checked', false);
                        $('input#color_' + data.couleurProd).prop('checked', true);
                        // Scroll to the top of the page in the case of a grouping

                        $('html, body').animate({
                            scrollTop: 0
                        }, 500);
                    }
                }

                if (wShop.$refs.wAlertestockForm) {
                    wShop.$refs.wAlertestockForm.reloadBloc += 1;
                }
            }
        },
        complete: function () {

            initItemSlider();

            if (!data.is_achat_express_v2) {

                checkProductInWishlist();

                // Toggle product features
                refreshToggleProduct();

                // Check if size is sleected before click on EresaShop button
                checkBtnEresaShop();

                // Init swiper slider on the page
                initSwiperSlide()

            }

            // Add a class to the element targeted by a clic
            sizeSelected();
            colorSelected();

            if (typeof lazyloadImage === 'function') {
                lazyloadImage();
            }
        }
    });
}

/**
 * Called upon color change in product page
 **/
function onItemColorChange(form, is_regroup, itm_rr_id) {
    var control, prod_id;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if (this.checked) {
        control = form.elements.namedItem(form.getValue('idCouleur'));
        control.value = this.value.trim();
        prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();
        itm_rr_id = itm_rr_id !== '' ? '_' + itm_rr_id.split('_')[1] : '';
        loadProduct.call(form, prod_id, 'couleur', '', itm_rr_id);
    
        setTimeout(() => {
            // Destroy existing Swiper instances
            if (typeof homeSwiper !== 'undefined' && homeSwiper instanceof Swiper) {
                homeSwiper.destroy(true, true);
            }
            
            if (typeof cartRecentVisitSwiper !== 'undefined' && cartRecentVisitSwiper instanceof Swiper) {
                cartRecentVisitSwiper.destroy(true, true);
            }
            
            // Reinitialize panier_tg_swiper
            panier_tg_swiper();
        }, 1000);
    }
}

/**
 * Called upon size change in product page
 **/
function onItemSizeChange(form, event, itm_rr_id) {
    let control;
    let is_wishlist = $('#wishlist').length > 0;

    if ($('#tunnel_right_col').length > 0) {
        is_wishlist = false;
    }

    let is_achat_express_v2 = ($('#is_achat_express_v2').val() == 1 && !is_wishlist);
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if (this.checked) {

        if (!is_achat_express_v2) {
            $('#btn_add_cart').val($('#btn_add_cart').data('label'));
        }

        $(this).value = this.value.trim();

        if (window.dataLayer !== undefined) {
            window.dataLayer.push({
                'event': 'changeSize',
                'produit': {
                    'size_value': ($(this).attr('data-nom')),
                }
            });

            if (window.dataLayer[0].product) {
                window.dataLayer[0].product.size = ($(this).attr('data-nom'));
            }
        }

        var id = form.getValue('produit_id');
        var elem = is_achat_express_v2 ? '_' + id + itm_rr_id : '';

        control = form.elements.namedItem('tailleProd');
        control.value = this.value.trim();

        if (!is_achat_express_v2) {

            // Check if the product is already in the wishlist

            checkProductInWishlist();
        }

        // Depending on the color, check if the product is available

        getAvailableQuantity($('#produit_principal' + elem).val(), $('#qteProd' + elem).val(), 'product', event, itm_rr_id);
    }

    // Activate the in-store reservation CTA (Call to Action)
    $('#btn_resa_shop').removeAttr('disabled');
    $("#sizeerror").removeClass('actif');
}

/**
 * Check product if exist in wishlist
 **/
function checkProductInWishlist() {
    var prod_id = $("#produit_id").val();
    var couleurProd = $("#couleurProd");
    if (couleurProd.length) {
        couleurProd = $("#couleurProd").val();
    } else {
        couleurProd = 0;
    }
    var tailleProd = $("#tailleProd");
    if (tailleProd.length) {
        tailleProd = $("#tailleProd").val();
    } else {
        tailleProd = 0;
    }

    if (typeof prod_id != "undefined" && typeof couleurProd != "undefined" && typeof tailleProd != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + 'get_user_wishlist.php',
            data: {'product_id': prod_id, 'size_id': tailleProd, 'color_id': couleurProd},
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true);
                    return true;
                } else {
                    changeBtnAddWishlist(false);
                    return false;
                }
            }
        });
    } else {
        changeBtnAddWishlist(false);
        return false;
    }
}

// Allows changing the action and the visual of the add to wishlist button

function changeBtnAddWishlist(in_wishlist) {

    if (in_wishlist) {
        $(".addToWishlistButton").addClass("existToWishlistButton");
    } else {
        $(".addToWishlistButton").removeClass("existToWishlistButton");
    }

}


/**
 * LIGHTBOX OPENING
 */
function openMultiShad(id) {

    $("#" + id).addClass("actif");

    if (id == 'lightbox_preco') { // Avoid closing for the preco cart lightbox

        $("#shad").addClass("actif");
    } else {

        $("#shad").addClass("actif").on("click", function () {

            closeMultiShad(id);
        });
    }

    if (id === "zoomBox") {
        $('#zoomBox').addClass("actif");
    }

}

function show_popup(elem) {
    openMultiShad(elem);
}

/**
 * LIGHTBOX CLOSING
 */
function closeMultiShad(id) {

    $('#shad').removeClass('actif');
    $('#' + id).removeClass('actif');
}

function close_popup(elem) {
    closeMultiShad(elem)
}

/*************************************/
/******** affichelostpassword ********/
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subLogin.animate({
        opacity: 0
    }, 600, function () {

        subLogin.css('display', 'none');
        subPassword.css('display', 'block').animate({
            opacity: 1
        }, 600);
    });
};


/* STORE LOC */

// Creation of a block corresponding to a marker on the sidebar list

function creerLigneListe(cpt, id, nom, adresse, ville, cp, pays, currently_open, horaire_today, distance, marqueur, picture_filtre, magasinId, stock, stockStatus, stock_quantity, clientId, favorite, beContacted, flag_available, url, shop_filter, telephone, query, lat, lng) {
    currently_open = parseFloat(currently_open);
    var li = document.createElement('li');
    li.id = id;

    li.className = 'elem-list-store';


    /***** Product stock infos *****/
    if (stock_quantity == 'undefined' || stock_quantity == '') {
        stock_status_msg = 'unknow_stock';
    } else {
        var in_stock = (stock_quantity >= 6 && stock_quantity <= 9999);
        var limited_stock = (stock_quantity >= 1 && stock_quantity <= 5);
        var not_in_stock = stock_quantity == 0;
        var to_be_confirmed_stock = stock_quantity == 10000;
        var can_be_ordered_stock = stock_quantity == 11000;

        var stock_status_msg = '';
        if (in_stock) {
            stock_status_msg = 'in_stock';
        }
        if (limited_stock) {
            stock_status_msg = 'limited_stock';
        }
        if (not_in_stock) {
            stock_status_msg = 'not_in_stock';
        }
        if (to_be_confirmed_stock) {
            stock_status_msg = 'to_be_confirmed_stock';
        }
        if (can_be_ordered_stock) {
            stock_status_msg = 'can_be_ordered_stock';
        }
    }

    var info_horaire_today = !horaire_today ? Translator.translate("store_closed") : horaire_today;
    var class_horaire_today = currently_open ? 'store_open' : 'store_closed';

    var ligneListe =
        '<div class="elem_list_contents">' +
        '<div class="nom_store" onclick="geolocGoto(\'' + lat + '\',\'' + lng + '\')">';
    if (distance) {
        ligneListe += '<h2 class="title">' + nom + '<p class="distance">' + '(' + distance + 'km)' + '</p></h2>';
    } else {
        ligneListe += '<h2 class="title">' + nom + '</h2>';
    }
    ligneListe += '</div>' +
        '<div class="content_store">'

    if ($('body.product_page').length) {
        ligneListe += `<div class="detail_store" onclick="location.href = '${path_relative_root + url}'">`
    } else {
        ligneListe += '<div class="detail_store">'
    }
    ligneListe += '<div class="col-1">' +
        '<span class="rue_store">' + adresse.toLowerCase() + '</span>' +
        '<span class="ville_store">' + cp + ' ' + ville.charAt(0).toUpperCase() + ville.slice(1).toLowerCase() + ' - ' + '<span class="pays_store">' + pays + '</span>' + '</span>' +
        '<span class="tel_store">' + telephone + '</span></div>';

    var is_page_product = $('body.product_page').length == 1;

    if ((info_horaire_today !== '' && currently_open) || (!currently_open && horaire_today)) {
        ligneListe += '<div class="col-2"><p class="horaire_today puce_' + class_horaire_today + '">' + info_horaire_today + '</p></div>';
    }

    ligneListe += '</div>';
    if (document.getElementById('produit_id')) {

        /***** Product stock infos msg *****/
        if (stock != 'undefined') {
            ligneListe += '<p class="stock_status ' + stock_status_msg + '">' + Translator.translate(stock_status_msg + '_msg') + '</p>';
        }
    }
    if (is_page_product) {

        if (beContacted == 1) {
            var contactStoreTxt = Translator.translate('contact_store');
            if ($('body.product_page.mobile').length) {
                ligneListe += '<div class="availableTxt store_more" onclick="handleChangeMobile(' + id + ')">' + '<span>' + contactStoreTxt + '</span>' + '</div>' + '</div>' + '</div>';
            } else {
                var nom_mag = nom.replace(/'/g, "\\'");
                ligneListe += '<div class="availableTxt store_more" onclick="handleChange(\'' + id + '\',\'' + nom_mag + '\')">' + '<span>' + contactStoreTxt + '</span>' + '</div>' + '</div>' + '</div>';
            }
        } else {
            ligneListe += '</div>' + '</div>';
        }
    }

    if (!is_page_product) {
        ligneListe += '<div class="store_more"><a onclick="createDataLayer(' + id + ')" href="' + path_relative_root + url + '">' + Translator.translate('afficher_horaires') + '</a>'
            + '</div>';
    }

    ligneListe += '</div>' + '</div>' + '</div>';

    li.innerHTML = ligneListe;

    li.addEventListener('click', function () {
        google.maps.event.trigger(marqueur, 'click');
    });


    return li;
}

function creerMarqueur(point, nom, adresse, ville, cp, pays, horaire, telephone, email, site, image_mag, type_mag, cpt, magasin_id, url, distance, currently_open, horaire_today) {

    var img = path_relative_root + "img/maps/marker.png";
    var http = new RegExp("^http://", "g");

    // Origins, anchor positions and coordinates of the marker
    // increase in the X direction to the right and in
    // the Y direction down.
    var image = new google.maps.MarkerImage(img,
        // This marker is 20 pixels wide by 32 pixels tall.
        new google.maps.Size(39, 53),
        // The origin for this image is 0,0.
        new google.maps.Point(0, 0));

    // The anchor for this image is the base of the flagpole at 0,32.
    var shadow = new google.maps.MarkerImage(path_relative_root + 'img/maps/marker.png',
        // The shadow image is larger in the horizontal dimension
        // while the position and offset are the same as for the main image.
        new google.maps.Size(39, 53),
        new google.maps.Point(0, 0),
        new google.maps.Point(10, 0));
    // Shapes define the clickable region of the icon.
    // The type defines an HTML <area> element 'poly' which
    // traces out a polygon as a series of X,Y points. The final
    // coordinate closes the poly by connecting to the first
    // coordinate.

    var marqueur = new google.maps.Marker({
        position: point,
        icon: image
    });

    marqueur.set("magasin_id", magasin_id);

    nom = $.trim(nom);
    adresse = $.trim(adresse);
    ville = $.trim(ville);
    cp = $.trim(cp);
    pays = $.trim(pays);
    horaire = $.trim(horaire);
    telephone = $.trim(telephone);
    email = $.trim(email);
    site = $.trim(site);
    distance = Math.round(distance);
    var info_horaire_today = !horaire_today ? '' : horaire_today;
    var class_horaire_today = currently_open ? 'store_open' : 'store_close';
    var currently_open_info = currently_open ? Translator.translate('store_opened') : Translator.translate('store_closed');


    if (distance) {
        var ligneListe = '<div class="info_popup"><div class="name"><a href="' + url + '">' + nom + '</a><span class="dist">(' + distance + ' km)</span>';
    } else {
        var ligneListe = '<div class="info_popup"><div class="name"><a href="' + url + '">' + nom + '</a></div>';
    }

    ligneListe += '<div class="adresse">' + adresse.toLowerCase() + '<br />' + cp + ' ' + ville.toLowerCase() + ' - ' + pays.toLowerCase() + '</div>';

    if (telephone != '')
        ligneListe += '<div class="coord">' + telephone + '</div>';

    if (site != '')
        if (!http.test(site)) {
            ligneListe += '<a href="http://' + site + '" class="site" target="_blank">' + site + '</a>';
        } else {
            ligneListe += '<a href="' + site + '" class="site" target="_blank">' + site + '</a>';
        }

    if (horaire != '')
        if (class_horaire_today == 'store_open') {
            ligneListe += '<div class="horaire_title"><span class = "' + class_horaire_today + '">' + currently_open_info + '</span>' + info_horaire_today + '</div><div class="horaire_info">' + horaire + '</div>';
        } else {
            //If closed we don't show "info_horaire_today" since it displays "Fermer Fermé"
            ligneListe += '<div class="horaire_title"><span class = "' + class_horaire_today + '">' + currently_open_info + '</span></div><div class="horaire_info">' + horaire + '</div>';
        }

    ligneListe += '<div class="btn_voir_magasin"><a href="' + url + '">' + Translator.translate('voir_magasin') + '</a></div></div>';

    google.maps.event.addListener(marqueur, "click", function () {
        if (!infowindow) {
            infowindow = new google.maps.InfoWindow();
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        } else {
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        }
    });

    var detail_store_panel = document.getElementById("store_id_dataLayer");
    detail_store_panel.textContent = magasin_id

    markersArray.push(marqueur);
    return marqueur;

}


function loadFadeInStoreLoc() {
    const opacityDelay = 1000;
    const selector = document.querySelector('.wrapper_store');

    if (document.body.classList.contains('store_locator')) {
        applyFadeInEffect(opacityDelay, selector);
    }
}

/* END STORE LOC */


// Function to apply a fadeIn effect on an element. It requires a selector as a parameter and the animation delays also dont forget to set opacity of your selector at 0 in your css file.
// See loadFadeInStoreLoc() for an example of usage.


function applyFadeInEffect(opacityDelay, selector) {

    setTimeout(() => {
        selector.classList.add('visible');
    }, opacityDelay);
}


/****** FILTER FUNCTIONS ******/
/**
 * Reset all fiters
 */
function initAllFilters() {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0);

    ctrl.forEach(function (elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {

                $('#resetFilter').val(1);

                elm.click();

                // Remove is_checked classes
                if ($(elm).hasClass('is_checked')) {
                    $(elm).removeClass('is_checked');
                }
            }
        }
    });

    $('a.tri_price.actif').removeClass('actif');
    $(".filtre_elem").each(function (index) {
        $(this).removeClass("is_checked");
    });
    $(".filters_dropdown_content input[type='checkbox']").each(function (index) {
        $(this).prop("checked", false);
    });

    var prix_init_min = $('input[name="initial_min_price"]').val();
    var prix_init_max = $('input[name="initial_max_price"]').val();
    var minPriceHandler = $('#slider-range .ui-slider-handle:first');
    var maxPriceHandler = $('#slider-range .ui-slider-handle:last');

    $("#amount1").val(prix_init_min);
    $("#amount2").val(prix_init_max);
    $("#slider-range").slider("option", {
        min: parseInt(prix_init_min),
        max: parseInt(prix_init_max),
        values: [parseInt(prix_init_min), parseInt(prix_init_max)],
    });

    minPriceHandler.html('<span class="handler_price">' + prix_init_min + window.siteCurrency.symbol_right + '</span>');
    maxPriceHandler.html('<span class="handler_price">' + prix_init_max + window.siteCurrency.symbol_right + '</span>');

    $('#trigger_filtre .initFilters').hide();
}

function filterTrigger() {
    "use strict";

    var filter_right = $(".filters");
    var shadfilter = $("#shad");

    if (filter_right.hasClass("opened")) {
        closeFiltre(); // Utilise hideFilters pour masquer les filtres
    } else {
        filter_right.addClass("opened");
        shadfilter.addClass("filterShad").addClass("actif");
        $(".filtersWrapper").toggleClass("on off");
    }

    shadfilter.on("click touch", closeFiltre); // Utilise directement hideFilters
}

function closeFiltre() {
    $(".filters").removeClass("opened");
    $("#shad").removeClass("actif filterShad");
    $(".filtersWrapper").toggleClass("on off");
    $('#choix_recherche, #choix_recherche .filtre_elem').removeClass('opened');
    $('#choix_recherche').css('z-index', '0');
    $('.tablet .headerRollover').removeClass('actif');
}

function filterToggleElem() {
    $('.toggleFilter').on('click touch', function () {
        if ($(this).parents('.filter_wrapper').hasClass('collapsible')) {
            $(this).parents('.filter_wrapper').removeClass('collapsible');
            $(this).parents('.filter_wrapper').find('.selected_options').slideUp();
        } else {
            $(this).parents('.filter_wrapper').addClass('collapsible');
            $(this).parents('.filter_wrapper').find('.selected_options').slideDown();
        }
    });
}

/**
 * Count active filters in rayon
 */
function countItemFilter(input, translation) {

    var filterName = input.name;
    var filters = $('input:checked[name=' + filterName + ']');
    var countFilters = filters.length

    if (countFilters > 0) {

        $("#filter_sticky [data-filtername='" + filterName + "'] .toggleFilter").addClass('actif');
        if ($("#filter_sticky [data-filtername='" + filterName + "'] .toggleFilter").length) {

            if ($("#filter_sticky [data-filtername='" + filterName + "'] .toggleFilter").find('.count_filters').length) {

                $("#filter_sticky [data-filtername='" + filterName + "'] .toggleFilter .count_filters").html("(" + countFilters + ")");
            } else {

                $("#filter_sticky [data-filtername='" + filterName + "'] .toggleFilter").append('<span class="count_filters">(' + countFilters + ')</span>');
            }
        }
    } else {

        if ($("#filter_sticky [data-filtername='" + filterName + "'] .toggleFilter .count_filters").length) {

            $("#filter_sticky [data-filtername='" + filterName + "'] .toggleFilter").removeClass('actif');
            $("#filter_sticky [data-filtername='" + filterName + "'] .toggleFilter .count_filters").remove();
        }
    }
}

function achatExpressV2() {
}

/**
 * To load all the products of the page
 * @author
 * @since
 *
 * @param  String            type       [description]
 * @param  Number            idObj      [description]
 * @param  Number            page       [description]
 * @param  Number            nb_product [description]
 * @param  HTMLElement        btn       the button that was clicked to load the elements (if undefined, no problem)

 *
 * @return void
 */

var bloc_prod_xhr;

function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollTop, fromScrollBottom, fromPagination = false) {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        output = {};

    output = triggerFilter(page, type, idObj, nb_product, 'filter_sticky', fromPagination);

    let currentPage = $('#initial_page').val();
    output.current_page = parseInt(currentPage) + 1;

    const maxItems = document.getElementById('totalElems').value;
    const productLoadedFull = $('#list_item .item:not(".push")').length;
    const pagerNav = document.querySelector('.pagerNav.end_pagi');
    const button = pagerNav?.querySelector('.button.primary');

    if (fromScrollTop || fromScrollBottom) {
        $('#initial_page').val(page + 1);
        $('#is_loading').val('1');
    }

    if (page !== 'all') {

        if (nb_product === 'all') {

            $('.see_all').html(
                '<img src="' + path_relative_root + 'img/loader.gif" width="20" height="20" />'
            );

        }

        if (bloc_prod_xhr) {

            bloc_prod_xhr.abort();
        }

        // Sometimes, we get less products than the number of products we should display. It shouldn't happen, but it happens. This var helps us to hide the button "see more" when no more products can be fetched
        var productLoadedPrevious = $('#list_item .item:not(".push")').length;

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + 'ajax_reload_products.php',
            type: 'get',
            data: output,
            success: function (res) {

                var old_elm = document.getElementById('list_item');
                var content;
                var itm_length;

                if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {

                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');

                content = res[0].trim();
                itm_length = parseFloat(res[1]);

                let canonical = res[5];
                let prev = res[6];
                let next = res[7];

                $("link[rel^='canonical']").attr("href", path_root + canonical);
                $("link[rel^='alternate'][hreflang^='fr']").attr("href", path_root + canonical);

                // specific to Kujten which only has the language EN in addition to FR
                $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8]).en);

                let hrefPrev = $("link[rel^='prev']");
                let hrefNext = $("link[rel^='next']");
                let commentPrev = $('#href_prev');
                let commentNext = $('#href_next');

                changeSeoElement(prev, hrefPrev, commentPrev, 'prev', seo, $("#lightbox_achat_express_v2"), $(".textSeo"));
                changeSeoElement(next, hrefNext, commentNext, 'next');

                if (content !== '') {

                    if (!isNaN(page)) {
                        old_elm.setAttribute('data-p', (page * nb_product));
                    }

                    if (fromScrollBottom || fromScrollTop) {
                        $(".autoLoad").fadeTo("fast", 0);
                    } else {
                        old_elm.innerHTML = "";
                    }

                    document.getElementById('totalElems').value = itm_length;
                    paginate(page + 1);
                    old_elm.insertAdjacentHTML('beforeend', content);

                    /* Add total product number of filtered items */
                    if ($('#resetFilter').val() == 0) {
                        $("#validate_filter").html(Translator.translate("apply_item_filters") + " (" + itm_length + ")");
                    } else {
                        $("#validate_filter").html(Translator.translate("apply_item_filters"));
                    }

                    if (nb_product === 'all') {
                        $('.see_all').html('');
                    }

                    $('#resetFilter').val(0);
                }
            },
            complete: function () {

                $('#totalElems').trigger('change');
                $('#bottom_reached').val("0").trigger('change');
                $('#page').trigger('change');

                if (fromScrollTop || fromScrollBottom) {
                    $('#is_loading').val('0');
                }

                if (!fromScrollBottom && !fromScrollTop) {
                    setTimeout(function () {
                        $("html, body").animate({
                            scrollTop: 0
                        }, 200);
                    }, 250);
                    //reset initiialPage if from filter
                    $("#initial_page").trigger("change");
                }

                total_element = form.getValue('totalElems', parseFloat);

                // Display number of product on the right side of top ray filters
                $('#nbrFilteredProd span').html(total_element);

                /**
                 * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
                 */
                var productLoaded = $('#list_item .item:not(".push")').length;
                var percentLoaded = (productLoaded * 100 / total_element);

                $('.loader_scroll').removeClass('loading');

                if (
                    productLoaded >= total_element ||
                    total_element == productLoadedPrevious
                ) {
                    $('.loader_scroll').hide();
                } else {
                    $('.loader_scroll').show();
                }

                setTimeout(function () {
                    if (productLoadedFull === maxItems) {
                        pagerNav.classList.add('cache');
                    }
                }, 500);

            }
        });
    }
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {

    page = page || 1;
    let page_bloc = parseInt(page - 1);

    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    if ($('#search_page').length > 0) {

        generateNewBlocSearch(page_bloc);
    } else {

        generateNewBlocProd(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop, true);
    }
}

function paginate(page) {

    if (document.getElementById('filter_sticky')) {
        var form = document.getElementById('filter_sticky'),
            total_element = form.getValue('totalElems', parseFloat),
            nb_prod = form.getValue('nb_prod', parseFloat),
            nb_total_page = Math.ceil(total_element / nb_prod),
            pagination = $('.pager_wrapper'),
            nb_prod_default = form.getValue('nb_prod_default', parseFloat);
        see_all = form.getValue('see_all', parseFloat);
        page = page || 1;

        $.ajax({
            url: path_relative_root + 'ajax_reload_pagination.php',
            type: 'get',
            data: {
                page: page,
                link: 'paginationGoToPage()',
                nb_total_page: nb_total_page
            },
            success: function (res) {

                var productLoaded = $('#list_item .item:not(".push")').length;
                var percentLoaded = (productLoaded * 100 / total_element);

                pagination.each(function () {
                    if (nb_prod != 1000 && nb_prod < total_element) {
                        this.innerHTML = res + '<div class="see_all_product"</div>';
                    } else if (total_element > nb_prod_default) {
                        this.innerHTML = '<div class="see_all_product"</div>';
                    } else {
                        this.innerHTML = '';
                    }
                });
            }
        });
    }
}

/**
 * Filter orders by type
 */
function show_orders_by_type(elementID, type, parentPath, reload) {

    var str_year = '';
    var str_reload = '';
    var year = $("#year").val();

    if (year != '') {

        str_year = '&year=' + year;
    }

    if (reload !== undefined) {

        str_reload = '&reload=true';
    }

    // Gestion de la surbrillance des onglets
    var i = 1;
    while (document.getElementById("com_onglet_" + i)) {

        if (type == i) {

            $("#com_onglet_" + i).addClass("actif");
        } else {

            $("#com_onglet_" + i).removeClass("actif");
        }
        i++;
    }

    var container = document.getElementById(elementID);

    //clearTimeout(time_out);
    if (container) {

        var response = ajax_file(parentPath + 'ajax_account_orders_by_type.php?type=' + type + str_year + str_reload);
        if (response) {

            if (reload !== undefined) {

                $('.content_commandes').html(response);
                setTimeout(function () {
                    $('.content_commandes').css('opacity', '1');
                }, 500);

            } else {

                $(container).html(response);
                setTimeout(function () {
                    $('.content_commandes').css('opacity', '1');
                }, 500);

            }
        } else {

            container.innerHTML = '';
            setTimeout(function () {
                $('.content_commandes').css('opacity', '1');
            }, 500);

        }
    }
}

/******* SEARCH END TRIGGERED FUNCTION *******/

function triggerFunctionOnSearchEnd() {
    initItemSlider(); //init product sliders on search end
    filterToggleElem(); //filter collapse element
    $("html, body").animate({
        scrollTop: 0
    }, 200);

    $('.total_articles').on('click touch', function () {
        $('.wrap_search_field').toggleClass('is-opened-search');
        $('#search_home').focus();
        $('#search_home').val('');
    })
}

//Overlay Scrollbar Body
if (!$('html.tablet').length) {
    const osInstance = OverlayScrollbars(document.body, {});
}

// handle tablet cart and account menu
if ($('html.tablet').length) {

    // top cart case
    $('#cart_top').on('click touch', function () {
        if ($('#cart_top').hasClass('actif')) {
            $('#cart_top').removeClass('actif');
        } else {
            $('#cart_top').addClass('actif');

            // account menu case
            if ($('#account_top').hasClass('actif')) {
                $('#account_top').removeClass('actif');
            }
            // search case
            if ($('.wrap_search_field').hasClass('is-opened-search')) {
                $('.wrap_search_field').removeClass('is-opened-search');
            }
            // menu case
            if ($('.tablet_menu').hasClass('is-opened-menu')) {
                $('.tablet_menu').removeClass('is-opened-menu');
            }
        }
    })

    // account menu case
    $('#account_top').on('click touch', function () {
        if ($('#account_top').hasClass('actif')) {
            $('#account_top').removeClass('actif');
        } else {
            $('#account_top').addClass('actif');

            // top cart case
            if ($('#cart_top').hasClass('actif')) {
                $('#cart_top').removeClass('actif');
            }
            // search case
            if ($('.wrap_search_field').hasClass('is-opened-search')) {
                $('.wrap_search_field').removeClass('is-opened-search');
            }
            //menu case
            if ($('.tablet_menu').hasClass('is-opened-menu')) {
                $('.tablet_menu').removeClass('is-opened-menu');
            }
        }
    })

    // search case
    $('.wrap_search_field').on('click touch', function () {
        if ($('.wrap_search_field').hasClass('is-opened-search')) {
            $('.wrap_search_field').removeClass('is-opened-search');
        } else {
            $('.wrap_search_field').addClass('is-opened-search');

            // account menu case
            if ($('#account_top').hasClass('actif')) {
                $('#account_top').removeClass('actif');
            }
            // top cart case
            if ($('#cart_top').hasClass('actif')) {
                $('#cart_top').removeClass('actif');
            }
            //menu case
            if ($('.tablet_menu').hasClass('is-opened-menu')) {
                $('.tablet_menu').removeClass('is-opened-menu');
            }
        }
    })
    // menu case
    $('.tablet_menu').on('click touch', function (e) {
        e.stopPropagation();
        if ($('.tablet_menu').hasClass('is-opened-menu')) {
            $('.tablet_menu').removeClass('is-opened-menu');
        } else {
            $('.tablet_menu').addClass('is-opened-menu');
            $('#account_top').removeClass('actif');
            $('#cart_top').removeClass('actif');
            $('.wrap_search_field').removeClass('is-opened-search');
        }
    });


    // remove menu overlays when click on shad
    $('#shad_menu').on('click touch', function () {
        $('#account_top').removeClass('actif');
        $('#cart_top').removeClass('actif');
        $('.wrap_search_field').removeClass('is-opened-search');
        $('.tablet_menu').removeClass('is-opened-menu');
    })

}

/**************************
 ****** INIT SWIPERS ******
 **************************/
/**
 *  Pre-header slider
 */

if ($('.promo_swiper').length) {

    const promo_swiper = new Swiper('.promo_swiper', {

        slidesPerView: 1,
        loop: true,
        speed: 2200,
        autoplay: {

            delay: 4500
        },
        allowTouchMove: false,
    });
}

/**
 * Shows button's underlying loader and triggers defined action
 */
var elmt;
var loader;
var prev_display;

function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");

    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function () {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // caution: do not change the time 1000 to allow intlInput enough time to properly populate the data

                //window.setTimeout(checkAllForm, 1000, tabError, 0, path_relative_root, id_form);
                break;

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

/**
 *Client disconnexion
 */
function ajaxDeconnect(path_relative_root) {

    var provenance = window.location.pathname,
        cpt_client = new RegExp(/(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/),
        response;

    // Get the current page's name
    provenance = provenance.substr(provenance.lastIndexOf('/'), provenance.lastIndexOf('.'));

    // This actually disconnects the client
    response = ajax_file(path_relative_root + 'ajax_deconnect.php');

    // If there was an error
    if (!response)
        alert('Erreur lors de la déconnexion.');

    // Else if the current page can't be displayed when not logged in: redirect
    else if (cpt_client.test(provenance))
        window.location.href = path_relative_root + create_link('connexion_login');

    // Else: refresh
    else window.location.reload();

    // If the page hasn't been reloaded: remove the loader and return false
    hideLoader();

    return false;
}

function isScrollTop() {
    if ($(window).scrollTop() > 0 && !$("#site_head_wrap.sticky").length) {
        $("#site_head_wrap").addClass("sticky");
    } else if ($(window).scrollTop() == 0) {
        $("#site_head_wrap").removeClass("sticky");
    }
}

/**
 * Global .item_container slider initialization
 * isToggled => is true when from a toggle between 1 or 2 products per line (function defined in _app)
 */
function initItemSlider() {

    if ($('.prodItemSlider').length) {
        var swiperLazy = true; // is_lazy_load defined in _app -> views_mobile_new -> main.php

        $('.prodItemSlider').each(function () {

            id = $(this).parents('.item').attr('id');

            if (isInViewport($(this)) && !$(this).hasClass('initialized') && $(this).find('.swiper-slide').length > 1) { // Prevent initialized sliders to go back to the first frame during scroll

                new Swiper((this), {
                    roundLengths: true,
                    slidesPerView: 1,
                    lazy: true,
                    preloadImages: false,
                    navigation: {
                        nextEl: '#' + id + ' .rollover_rayonsNext',
                        prevEl: '#' + id + ' .rollover_rayonsPrev',
                    },
                });

                $(this).addClass('initialized');
            }
        });
    }
}

/******** ON PAGE LOAD FUNCTIONS **********/
$(function () {
    isScrollTop();
    $(window).on('scroll', function () {
        isScrollTop();
    });

    $(".js-closeLightboxNewsletter").on('click', function () {
        $('#exit_overlay_OVI').hide();
        $("#shad").hide();
    })

    // Burger Menu
    $('.tablet_menu_wrapper').on('click touch', function () {
        if ($('.tablet_menu').hasClass('is-opened-menu')) {
            $('.categ_name').removeClass('is-disabled-categ');
            $('.subcat_name').removeClass('is-disabled-subcat');
            $('.categ_container').show();
            $('.subcat_wrapper').show();
            $('.tablet_menu').removeClass('is-opened-menu');
            var osMenu = OverlayScrollbars(document.querySelector('.leftPart'), {}).destroy();
        } else {
            $('.categ_name').addClass('is-disabled-categ');
            $('.subcat_name').addClass('is-disabled-subcat');
            $('.categ_container').hide();
            $('.subcat_wrapper').hide();
            $('.tablet_menu').addClass('is-opened-menu');
            var osMenu = OverlayScrollbars(document.querySelector('.leftPart'), {
                elements: {
                    viewport: document.querySelector('headerContainer:has(.is-opened-menu) .leftPart'),
                },
            });
        }
    });
    //categ link management for burger menu
    $(".categ_link").on('click touch', function (event) {
        if ($('.headerContainer:has(.is-opened-menu)').length && $(this).parent('.is-disabled-categ').length > 0) {
            event.preventDefault();
            $('.categ_container').slideUp();
            $(this).parent('.categ_name').next('.categ_container').slideDown();
            $('.categ_name').addClass('is-disabled-categ');
            $(this).parent('.categ_name').removeClass('is-disabled-categ');
        }
    });

    //subcateg link management for burger menu
    $(".subcat_link").on('click touch', function (event) {
        if ($('.headerContainer:has(.is-opened-menu)').length && $(this).parent('.is-disabled-subcat').length > 0 && $(this).parent('.subcat_name').next('.subcat_wrapper').find('.object_name').length > 1) {
            event.preventDefault();
            $('.subcat_wrapper').slideUp();
            $(this).parent('.subcat_name').next('.subcat_wrapper').slideDown();
            $('.subcat_name').addClass('is-disabled-subcat');
            $(this).parent('.subcat_name').removeClass('is-disabled-subcat');
        }
    });

    $(window).resize(function () {
        //reinit burger menu on resize over 1440px
        if ($(window).width() > 1440 && $('.tablet_menu').hasClass('is-opened-menu')) {
            $('.tablet_menu').removeClass('is-opened-menu');
            $('.categ_name').removeClass('is-disabled-categ');
            $('.subcat_name').removeClass('is-disabled-subcat');
            $('.categ_container').slideDown();
            $('.subcat_wrapper').slideDown();
            var osMenu = OverlayScrollbars(document.querySelector('.leftPart'), {}).destroy();
        }

        // lookbook tag direction
        if ($('.productTag').length) {
            $(window).resize(function () {
                $('.productTag').each(function () {
                    directionTag($(this), 275);
                });
            });
        }
    });

    // Search Trigger
    $(".trigger_recherche, .wrap_search_field .closeBtn").on('click touch', function () {
        $('.wrap_search_field').toggleClass('is-opened-search');
    });

    // hovering menu management
    $('.menu_wrapper:has(.categ_container)').on('mouseenter', function () {
        $(this).addClass('is_hovered');
    });
    $('.menu_wrapper').on('mouseleave', function () {
        $('.menu_wrapper').removeClass('is_hovered');
    });

    if ($('#rayon_sticky_block').length) {
        $(window).on('scroll', function () {

            if ($(window).scrollTop() > 190) {
                $('#rayon_sticky_block').addClass('sticky');
            } else {
                $('#rayon_sticky_block').removeClass('sticky');
            }
        });
    }

    /* Load global product swiper initialization on window scroll */
    if ($('.prodItemSlider').length) {
        $(window).on('scroll', function () {
            initItemSlider();
        });
    }

    $(document).on('mouseenter', '.prodItemSlider', function () {
        initItemSlider();
    });

    /* init product swiper on document ready */
    initItemSlider();

    // Apply fade in effect on store loc container page
    // loadFadeInStoreLoc();

    //Load only if the page has the class '.homepage'
    if ($('.homepage').length) {
        // Initialisation swiper module 1 home
        initializeSwiper('.sliderMainHome', {
            pagination: {
                el: '.cover-pagination',
            },
            autoplay: {
                delay: 5000,
            },
        });

        // Initialisation swiper module 9 home
        initializeSwiper('.home_module.tg_module .main-swiper', {
            slidesPerView: 4,
            spaceBetween: 15,
            allowSlidePrev: true,
            allowSlideNext: true,
            watchSlidesProgress: true,
            loop: true,
            navigation: {
                nextEl: '.tg-module-next',
                prevEl: '.tg-module-prev',
            },
            pagination: {
                el: '.tg_pagination',
                clickable: true,
            }
        });
    }

    //Load only if the page has the class '.product_page'
    if ($('.product_page').length) {

        // Check if size is sleected before click on EresaShop button
        checkBtnEresaShop();

        // Add a class to the element targeted by a clic
        sizeSelected();
        colorSelected();

        // Toggle product features
        refreshToggleProduct();
    }

    //Load only if the page has the class '.in_tunnel'
    if ($('.in_tunnel').length) {
        toggleListProduct();
    }

    //Load only if the page has the class '.cart'
    if ($('body').hasClass('cart')) {
        toggleModifySize();
        $("#cart_slider_wrapper").insertAfter($("#main_cart_wrapper"));
        panier_tg_swiper();
        lightboxEventListenner();
    }

    //filter collapse element
    filterToggleElem();

    // Init swiper slider on the page
    initSwiperSlide();

    // Load only if the page has the class '.cart .step_2'

    if ($('.cart.step_2').length) {
        hideBlocFaq();
    }

    //seo bloc end page rayon toggle
    $('.seo_div .read_more, .seo_div .read_less').on('click touch', function () {
        if ($(this).parents('.seo_div').find('.full').hasClass('cache')) {
            $('.seo_div .trimed').addClass('cache');
            $(' .seo_div .trimed').removeClass('displayed');
            $(' .seo_div .full').addClass('displayed');
            $(' .seo_div .full').removeClass('cache');
        } else {
            $(' .seo_div .trimed').addClass('displayed');
            $(' .seo_div .trimed').removeClass('cache');
            $('.seo_div .full').addClass('cache');
            $(' .seo_div .full').removeClass('displayed');
        }
    });

    // lookbook tag direction
    if ($('.productTag').length) {
        $('.productTag').each(function () {
            directionTag($(this), 275);
        });
    }

    updateCartUI();

    const observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            updateCartUI();
        });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    if ($('body').hasClass('cart')) {
        setTimeout(() => {
            $('.product_towish.existToWishlistButton').on('click', function() {
                $('#shad_abox').addClass('actif');
            });
        }, 3000);

        $('#abox .close_pop').on('click', function() {
            $('#shad_abox').removeClass('actif');
        });
    }

    // For Hompage text modules, adds more space between title and text [WP-28349]
    if ($('body.homepage').length) {
        $('.home_module.text span:has(> strong:only-child) > strong, .home_text_container span:has(> strong:only-child) > strong').css({
            'display': 'inline-block',
            'margin-bottom': '15px'
        });
    }

    if ($('.main_wrapper .store_locator .go_store').length) {
        $('.main_wrapper .store_locator .go_store').prependTo('.innerWrapper .left_bloc');
    }

    checkCartChildren();
});

function directionTag(elem, offset) {
    if (elem.offset().left < offset) {
        elem.addClass('right');
    } else {
        elem.removeClass('right');
    }
}

// PRODUCT PAGE

function sizeSelected() {
    $('.size_icon').click(function () {
        $('.size_selected').removeClass('size_selected');
        $(this).closest('.size').addClass('size_selected');
    });
}

function colorSelected() {
    $('.prodColor').each(function () {
        var $thisInput = $(this);
        var $closestColor = $thisInput.closest('.color');

        if ($thisInput.is(':checked')) {
            $closestColor.addClass('color_selected');
        }

        $('label[for="' + $thisInput.attr('id') + '"]').click(function () {
            $('.color_selected').removeClass('color_selected');
            $closestColor.addClass('color_selected');
        });
    });
}

// On créer le swiper. Si la slide à l'index  0 a la classe vidéo alors le swiper s'arrête à la slide index 1.

function zoomImg(slide) {
    $("#zoom_box_wrapper img").each(function () {
        $(this).attr('src', $(this).attr('src').replace('/prodfp/', '/large/'));
    });

    window.setTimeout(function () {

        var productLightboxVisu = new Swiper('#zoom_box_wrapper', {
            navigation: {
                nextEl: '.zoomProdNext',
                prevEl: '.zoomProdPrev'
            },
            on: {
                slideChange: function () {
                    const index_currentSlide = this.realIndex;
                    const firstSlide = this.slides[index_currentSlide - 1];
                    if (index_currentSlide === 1 && firstSlide.classList.contains('slide_video')) {
                        document.querySelector('.zoomProdPrev').style.display = "none";
                        this.allowSlidePrev = false;
                    } else {
                        document.querySelector('.zoomProdPrev').style.display = "block";
                        this.allowSlidePrev = true;
                    }
                },
            },
        });

        if (slide == null) {
            productLightboxVisu.slideTo(productMainVisu.activeIndex);
        } else {
            var correctedSlide = slide > 0 ? slide - 1 : 0;
            productLightboxVisu.slideTo(correctedSlide);
        }

    }, 50);

    openMultiShad('zoomBox');
}

/**
 * Refresh toogle event for product
 */
function refreshToggleProduct() {
    if ($('.toggleCarac').length) {
        $('.toggleCarac').on('click', function () {
            var content = $(this).next('.content');
            if ($(this).hasClass('actif')) {
                $(this).removeClass('actif');
                content.slideUp(function () {
                    content.removeClass('actif');
                });
            } else {
                $(this).addClass('actif');
                content.slideDown(function () {
                    content.addClass('actif');
                });
            }

            $('#toggleBatchCharact, .toggleBatchCharactWrapper').removeClass('actif');
        });
    }
}


/**
 * Get product data
 */
function getProductDetail(productId) {

    let form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + productId);

    let productColor = form.getValue('idCouleur');
    let val_color = form.getValue(productColor);

    let productSize = form.getValue('idTaille');
    let val_size = form.getValue(productSize);

    let data = {
        produitId: productId,
        colorId: val_color,
        sizeId: val_size,
        json: 'json'
    };

    $.ajax({
        url: path_relative_root + 'ajax_product_data.php',
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {

            // Set event ID for product detail dataLayer
            if (typeof (res) == 'object') {
                pushIntoDatalayer(res.dataLayerData.productDetails);
            }
        }
    });
}

// /**
//  * In the product information sheet, display an email field so that we can be notified when the product is available.
//  */
function displayAlertStock() {

    if (!$('#btn_add_alert').is(':visible')) {
        $('#btn_add_alert').show();
    }

    var $taille_id = $("#tailleProd").val(),
        $couleur_id = $("#couleurProd").val();

    if ($taille_id == '') {
        if (!$('#sizeerror').is(':visible')) {
            $('#sizeerror').slideDown();
        }
        return;
    } else if ($couleur_id == '') {
        alert(Translator.translate('js_error_couleur'));
        return;
    }

    var isFormVisible = $('.bloc_add_alert_form').is(':visible');
    var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');
    var isErrorVisible = $('.bloc_add_alert_erreur').is(':visible');

    if (isFormVisible || isMessageVisible || isErrorVisible) {
        if (isFormVisible) {
            $('.bloc_add_alert_form').slideUp('fast');
        }

        if (isMessageVisible) {
            $('.bloc_add_alert_confirmation').slideUp('fast');
        }

        if (isErrorVisible) {

            $('.bloc_add_alert_erreur').slideUp('fast');
        }
    } else {
        $('#bloc_add_alert_form').slideDown('fast');
        $('#btn_alert_stock').hide();
    }
}


function verif_telephone(elementID, fieldname, is_mobile) {

    // type 0 -> landline (01)
    // type 1 -> mobile (06)
    // type 3 -> landline (08)
    // type 6 -> landline (09)
    // The plugin refuses 07, so if it is a 07, we do not go through the plugin ( intlTelInput("isValidNumber") ).

    var objElementHidden = $('#' + elementID + 'Hidden');
    var objElement = $('#' + elementID);
    var erreurText = document.getElementById('erreur_msg');

    var inputMobile = '';
    var inputTel = '';
    if ($('#mobileHidden').length > 0) { //tunnel
        inputMobile = $('#telephoneHidden');
        inputTel = $('#mobileHidden');
    } else if ($('#clientMobileHidden').length > 0) { //mes info
        inputMobile = $('#clientMobileHidden');
        inputTel = $('#clientTelHidden');
    } else { // newsletter
        inputMobile = inputTel = objElementHidden;
    }

    var value = objElement.intlTelInput("getNumber");
    var type = objElement.intlTelInput("getNumberType");

    if ($.trim(objElement.val()).length > 0) {
        if (value.length == 12 && value.substr(1, 3) == '337') {
            inputMobile.val(value);
        } else {
            if (objElement.intlTelInput("isValidNumber") !== false && (type === 0 || type === 3 || type === 6)) {
                inputTel.val(value);
            } else if (objElement.intlTelInput("isValidNumber") !== false && type === 1) {
                inputMobile.val(value);
            } else if (objElement.intlTelInput("isValidNumber") !== false && type === 2) {
                inputTel.val(value);
                inputMobile.val(value);
            } else {
                objElement.addClass('inputErr');
                return false;
            }
        }
        return true
    } else {
        objElement.addClass('inputErr');
        return false;
    }
}

function checkForm(
    type,
    elem,
    fieldname,
    from,
    blockMenuTopConnexionInfobulle
) {
    let countryValue = ($("#pays").length > 0 ? $("#pays").val() : '');

    if (blockMenuTopConnexionInfobulle == undefined) {
        blockMenuTopConnexionInfobulle = 0;
    }
    if (from != "click") {
        elementID = elem.id;
    } else {
        elementID = elem;
    }
    var already_mail = false;
    if (elem == "clientCivilite") elementID = elem;
    switch (type) {
        case "prix":
            var flag = verif_price(elementID, fieldname);
            break;
        case "alpha":
            var flag = verif_alpha(elementID, fieldname, false);
            break;
        case "alpha_optional":
            var flag = true;
            if (document.getElementById(elementID).value != "")
                flag = verif_alpha(elementID, fieldname, false);
            break;
        case "alphanum":
            var flag = verif_alphanum(elementID, fieldname, false);
            break;
        case "alphanumadresse":
            var flag = verif_alphanumadresse(elementID, fieldname, false);
            break;
        case "alphanum_optional":
            var flag = true;
            if (document.getElementById(elementID).value != "") {
                if (fieldname.indexOf("|") != -1) {
                    length = fieldname.substring(fieldname.indexOf("|") + 1);
                    fieldname = fieldname.substring(0, fieldname.indexOf("|"));
                    flag = verif_alphanum(elementID, fieldname, true, length);
                } else flag = verif_alphanum(elementID, fieldname, false);
            }
            break;
        case "same":
            elemCmpID = elementID.substring(0, elementID.length - 1);
            var flag = verif_alphanum(elementID, fieldname, false);
            if (flag) flag = verifSame(elemCmpID, elementID, fieldname);
            break;
        case "same_optional":
            var flag = true;
            elemCmpID = elementID.substring(0, elementID.length - 1);
            if (
                document.getElementById(elementID).value != "" ||
                document.getElementById(elemCmpID).value != ""
            ) {
                var flag = verif_alphanum(elementID, fieldname, false);
                if (flag) flag = verifSame(elemCmpID, elementID, fieldname);
            }
            break;
        case "mail":
            var flag = verif_mail(elementID, fieldname);
            break;
        case "mail_log":
            var flag = verif_mail(elementID, fieldname);
            if (flag) {
                var action = $("#" + elementID)
                    .closest("form")
                    .attr("action");
                var params =
                    "mail=" +
                    document.getElementById(elementID).value +
                    "&action=" +
                    action;
                flag = ajax_file_post(
                    path_relative_root + "ajax_email_exist.php",
                    params
                );
                var flag_ret = flag;
                if (flag == "1" || flag == "2") flag = false;
                else flag = true;
                if (!flag) {
                    var objElement = document.getElementById(elementID);
                    var objLabel = document.getElementById(elementID + "Label");
                    var erreurText = document.getElementById("erreur_msg");
                    if (flag_ret == "1")
                        erreurText.innerHTML =
                            '<p class="title_toolltip">' +
                            ajax_file(
                                path_relative_root +
                                "ajax_sprintf.php?arg1=" +
                                fieldname
                            ) +
                            " :</p>" +
                            ajax_file(
                                path_relative_root +
                                "ajax_sprintf.php?arg1=js_already_mail"
                            );
                    else
                        erreurText.innerHTML =
                            '<p class="title_toolltip">' +
                            ajax_file(
                                path_relative_root +
                                "ajax_sprintf.php?arg1=" +
                                fieldname
                            ) +
                            " :</p>" +
                            ajax_file(
                                path_relative_root +
                                "ajax_sprintf.php?arg1=login_erreur_17"
                            );
                    objElement.className = "inputErr";
                    if (objLabel) {
                        objLabel.className = "labelErr";
                    }
                    already_mail = true;
                }
            }
            break;
        case "mail_log_info":
            var flag = verif_mail(elementID, fieldname);
            if (
                flag &&
                document.getElementById(elementID).value !=
                document.getElementById("old_clientMail").value
            ) {
                flag = ajax_file(
                    "ajax_email_exist.php?mail=" +
                    document.getElementById(elementID).value
                );
                if (flag == "1") flag = false;
                else flag = true;
                if (!flag) {
                    var objElement = document.getElementById(elementID);
                    var objLabel = document.getElementById(elementID + "Label");
                    var erreurText = document.getElementById("erreur_msg");
                    erreurText.innerHTML =
                        '<p class="title_toolltip">' +
                        ajax_file("ajax_sprintf.php?arg1=" + fieldname) +
                        " :</p>" +
                        ajax_file(
                            "ajax_sprintf.php?arg1=js_already_mail_short"
                        );
                    objElement.className = "inputErr";
                    objLabel.className = "labelErr";
                    already_mail = true;
                }
            }
            break;
        case "mail_verif":
            elemCmpID = elementID.substring(0, elementID.length - 1);
            var flag = verif_mail(elementID, fieldname);
            if (flag) flag = verifSame(elemCmpID, elementID, fieldname);
            break;
        case "mail_optional":
            var flag = true;
            if (document.getElementById(elementID).value != "")
                flag = verif_mail(elementID, fieldname);
            break;
        case "radio":
            var flag = verif_radio(elementID, fieldname);
            break;
        case "select_date":
            var flag = verif_selected_date(elementID, fieldname);
            break;
        case "phone":
            //var flag = verif_num( elementID,fieldname,10 )
            var flag = true;
            if (flag) flag = verif_telephone(elementID, fieldname);
            break;
        case "mobile":
            var flag = true;
            flag = verif_num(elementID, fieldname, 10);
            if (flag) flag = verif_telephone(elementID, fieldname, true);
            break;
        case "cp":
            var flag = true;
            if (countryValue == 'B' || countryValue == 'L' || countryValue == 'S' || countryValue == 'PT')
                nb = 4;
            else
                nb = 5;
            if (countryValue != 'AD') {
                flag = verif_num(elementID, fieldname, nb);
            }
            if (flag) flag = verif_zip(elementID, 'pays', fieldname, nb);
            break;
        case "no_empty":
            var flag = verif_empty(elementID, fieldname);
            break;
        case "borne_code":
            var flag = verif_num(elementID, fieldname, 6);
            break;
        case "number":
            var flag = verif_num(elementID, fieldname, 5);
            break;
        case "is_valid": // Peremt de specifier que le champs sera toujours valid
            var flag = true;
            break;
    }

    var style = "";
    if (from == "click" || elem == "clientCivilite") {
        if (type == "select_date") {
            elem = document.getElementById(elem + "J");
            if (elementID == "newsletterDatenais")
                style = 'style="margin-left:45px;"';
            else if (elementID == "clientDatenais") {
                var nav = navigator.appVersion;
                style = 'style="margin-top:5px;margin-left:95px;"';
                if (nav.indexOf("MSIE") >= 0)
                    style = 'style="margin-left:53px;"';
            } else if (elementID == "garantieDateAchat") {
                style = 'style="margin: 5px 0 0 -10px"';
            }
            elementID += "J";
        } else if (type == "radio") {
            elem = document.getElementById(elem + "M");
            elementID += "M";
            style = 'style="left: 458px;"';
        } else elem = document.getElementById(elem);
    }
    if (elementID == "adresseTitre" && $.browser.webkit)
        style = 'style="margin:-22px 0 0 217px;"';

    if (!flag) {
        if (blockMenuTopConnexionInfobulle != 1) {
            if (
                $("#" + elementID)
                    .closest(".formItem_container, .input_container")
                    .children("img").length == 0
            ) {
                $("#" + elementID)
                    .closest(".formItem_container, .input_container")
                    .append(
                        "<img " +
                        style +
                        ' src="' +
                        path_relative_root +
                        'img/picto_panneau.png" alt="" class="img_error" />'
                    );
            }
            elem_img = $("#" + elementID)
                .closest(".formItem_container, .input_container")
                .children("img")[0];
        } else {
            document.getElementById(elem.id + "Err").style.display =
                "table-row";
            var nav = navigator.appVersion;
            if (nav.indexOf("MSIE 7") >= 0)
                document.getElementById(elem.id + "Err").style.display =
                    "block";
            if (
                $("#bloc_erreur").css("display") == "table-row" ||
                $("#bloc_erreur").css("display") == "block" ||
                $("#bloc_erreur").css("display") == "table"
            ) {
                $("#bloc_erreur").css("display", "none");
                clearTimeout(timer);
            }
        }
    }

    if (!flag) {
        var val = document.getElementById("erreur_msg").innerHTML;
        if (
            elem_img &&
            (elementID == "clientPwd" ||
                elementID == "clientMail" ||
                elementID == "clientMailAuth" ||
                elementID == "clientMailAuth" ||
                elementID == "clientPwdAuth" ||
                elementID == "mailLost") &&
            !already_mail &&
            blockMenuTopConnexionInfobulle != 1
        ) {
            elem_img.setAttribute("onmouseover", function () {
                openInfobulle("bloc_erreur", "spe", elem);
            });
            elem_img.onmouseover = function () {
                openInfobulle("bloc_erreur", "spe", elem);
            };
            elem_img.setAttribute("onmouseout", function () {
                closeInfobulle("bloc_erreur");
            });
            elem_img.onmouseout = function () {
                closeInfobulle("bloc_erreur");
            };
        } else if (elem_img && blockMenuTopConnexionInfobulle != 1) {
            elem_img.setAttribute("onmouseover", function () {
                OverPopup(this, val, "visible");
            });
            elem_img.onmouseover = function () {
                OverPopup(this, val, "visible");
            };
            elem_img.setAttribute("onmouseout", function () {
                OverPopup(this, val, "none");
            });
            elem_img.onmouseout = function () {
                OverPopup(this, val, "none");
            };
        }
    } else {
        if (blockMenuTopConnexionInfobulle == 1) {
            document.getElementById(elem.id + "Err").style.display = "none";
        }
        if (
            $("#" + elementID)
                .closest(".input_container")
                .children("img").length > 0
        ) {
            $("#" + elementID)
                .closest(".input_container")
                .children("img")
                .remove(".img_error");
        }
        if ($("#" + elementID).hasClass("inputErr")) {
            $("#" + elementID).removeClass("inputErr");
            //Au cas ou elle existerait deja, pour etre sur de ne l'ajouter qu'une seule fois
            $("#" + elementID).removeClass("inputForm");
            $("#" + elementID).addClass("inputForm");
        }
        if ($("#" + elementID + "Label").hasClass("labelErr")) {
            $("#" + elementID + "Label").removeClass("labelErr");
            //Au cas ou elle existerait deja, pour etre sur de ne l'ajouter qu'une seule fois
            $("#" + elementID + "Label").removeClass("labelForm");
            $("#" + elementID + "Label").addClass("labelForm");
        }
        if (
            $("#bloc_erreur").css("display") == "table-row" ||
            $("#bloc_erreur").css("display") == "block"
        ) {
            $("#bloc_erreur").css("display", "none");
            clearTimeout(timer);
        }
        if (from != "spe" && elem != "clientCivilite") {
            elem.setAttribute("onfocus", function () {
            });
            elem.onfocus = function () {
            };
        }
    }
    return flag;
}

function closeInfobulle(id) {
    $('#' + id).hide();
}

function btnLoaderUnloading(btn) {

    "use strict";

    var loader = btn.next(".loader");

    if (loader.length > 0) {

        setTimeout(function () {
            loader.removeAttr("style").parent().removeClass("loading")
        }, 200);
    }
}

// END PRODUCT PAGE

// TUNNEL

function addToWishlistUpdateHeader(nr) {

    if (!document.body.classList.contains('mobile')) {
        if (nr === 0) {
            $("#wishlist_top").removeClass('hasItem');

        } else {

            $("#wishlist_top .nbProd").html(nr);
        }

        var response_html = ajax_file(path_relative_root + 'ajax_show_wishlist.php');
        $("#wishlist_top").html(response_html);
    }
}

/**
 * Toggle product details in cart right column
 */

function toggleListProduct() {
    $(".total_produit.toggle").on("click", function () {
        $(this).toggleClass("open");
        $(".total_produit_dropdown").slideToggle();
    });
}

/**
 * Function who can hide the questions/answers bloc in the cart. Apply style in js because of the very specific case.
 */

function hideBlocFaq() {
    var $container = $('#tunnel_right_col');
    if ($container.find('.addresses_recap').length > 0) {
        $container.find('.wrap_bottom_rightcol').css('display', 'none');
    }
}

/**
 * Opens the size selection for modification in the cart.
 */

function toggleModifySize() {
    $('.toggle_modal_actions').on('click', function () {
        $(this).fadeOut('fast');

        var $cartItemActions = $(this).parents('.product_line_basket').find('.cart_item_actions');
        $cartItemActions.slideDown('slow');

        $cartItemActions.find('input').each(function () {
            var $thisInput = $(this);
            var $closestFormItem = $thisInput.closest('.form_itm');

            if ($thisInput.is(':checked')) {
                $closestFormItem.addClass('selected');
            }

            if ($thisInput.is(':disabled')) {
                $closestFormItem.addClass('disabled');
            }
        });
    });

    $('.form_itm.size').click(function () {
        $('.form_itm.size').removeClass('selected');
        $(this).addClass('selected');
    });
}

// Tunnel FAQ
function fadePanier(id, nbelem) {
    for (var i = 1; i <= nbelem; i++) {
        if (id != i && $("#texte_question_" + i).css('display') == 'block') {
            $("#texte_question_" + i).slideUp(400);
            $("#intitule_question_" + i).removeClass('actif');
        }
    }

    if ($("#texte_question_" + id).css('display') != 'block') {
        $("#texte_question_" + id).slideDown(400);
        $("#texte_question_" + id).addClass('actif');
        $("#intitule_question_" + id).addClass('actif');
    } else {
        $("#texte_question_" + id).slideUp(400);
        $("#texte_question_" + id).removeClass('actif');
        $("#intitule_question_" + id).removeClass('actif');
    }
}

// Alters item quantity in cart
function changeQte(type) {

    var types = ['plus', 'less'],
        item, loader, qtty, ajax_url;

    if (types.includes(type)) {

        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);

        this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'initial';

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url: path_relative_root + create_link("order_basket"),
            type: 'post',
            data: {
                action: 'del',
                panierId: item,
                cad: 'non',
                pointcad: 0,
                qteProd: qtty,
                change_qte: true
            },
            cache: false,
            success: function (res) {

                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();
                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc += 1;
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                document.getElementById('cart_top').innerHTML = response;

                cart = Translator.translate('mon_panier');
                var total = $('#cart_top .cart').attr('data-cart'),
                    text;

                if (total <= 1) {
                    text = Translator.translate('one_product');
                } else {
                    text = Translator.translate('2_product');
                }

                $('.cart_main_title.basket').html(cart + ' <span class="nb_products">(' + total + ' ' + text + ')</span>');
                $('#cart_total .nbProdCartTotal').html('<span class="nbProdCartTotal">' + total + ' ' + text + '</span>');

                /**
                 * Move out of stock products to the top of the cart
                 */
                updateCartElements();
            }
        });
    }
}

// END TUNNEL

function closeSizeNotAvailable(elem) {

    // Hide bloc bloc_size_not_available
    $("#bloc_size_not_available_" + elem).hide();

    // Show bloc colors and size
    $("#prod_info_" + elem + " .rollover_left").show();
    $("#ligne_pointure_" + elem).show();
    $("#ligne_couleur_" + elem).show();
}

$(window).on("removeBasketItemCustom", function (data) {
    toggleModifySize();
});

// Function that will be used to stop the autoplay of the swiper with an event listener to ensure that it happens. It is necessary to review the initialization of the swiper which initially takes place in the order_basket file of the app.

function stopExistingSwiperAutoplay() {
    var swiperContainer = document.getElementById('cart_slider_wrapper');
    if (swiperContainer && swiperContainer.swiper) {
        var existingSwiper = swiperContainer.swiper;
        if (existingSwiper.autoplay) {
            existingSwiper.autoplay.stop();
        }

        existingSwiper.on('autoplayStart', function () {
            existingSwiper.autoplay.stop();
        });
    }
}

var cartRecentVisitSwiper = null;

function panier_tg_swiper() {
    var swiperContainer = document.getElementById('cart_slider_wrapper');
    var swiperContainerBrowseRecently = document.getElementById('productVisitedSwiper');
    var swiperContainerBrowseRecentlyWide = document.getElementsByClassName('emptyBasketSwiper');
    var cartRecentVisitSwiper;
    var selectionoffertePopup;

    if (swiperContainer) {
        setTimeout(function () {
            homeSwiper = new Swiper('#cart_slider_wrapper #cart_slider_container', {
                slidesPerView: 4,
                spaceBetween: 15,
                navigation: {
                    nextEl: '#cart_slider_wrapper .swiper-button-next',
                    prevEl: '#cart_slider_wrapper .swiper-button-prev',
                },
                pagination: {
                    el: "#cart_slider_wrapper .cart_slider_pagination",
                },
                breakpoints: {
                    1025: {
                        slidesPerView: 4,
                    }
                },
                on: {
                    init: function () {
                        document.querySelector('#cart_slider_wrapper #cart_slider_container').style.opacity = 1;
                    }
                }
            });
            $('#cart_slider_wrapper').animate({opacity: 1}, 500);
        }, 1500);
    }

    if (swiperContainerBrowseRecently) {
        setTimeout(function () {
            // Destroy existing Swiper if it exists
            if (cartRecentVisitSwiper) {
                cartRecentVisitSwiper.destroy(true, true);
            }

            var slides = document.querySelectorAll('#productVisitedSwiper > .swiper-wrapper > .item.swiper-slide');
            setTimeout(() => {
                if (slides.length > 3) {
                    cartRecentVisitSwiper = new Swiper('#gondole_derniers_articles_vus #productVisitedSwiper', {
                        slidesPerView: 3.15,
                        spaceBetween: 15,
                        navigation: {
                            nextEl: '#gondole_derniers_articles_vus .swiper-button-next',
                            prevEl: '#gondole_derniers_articles_vus .swiper-button-prev',
                        },
                        pagination: {
                            el: ".panier-swiper-pagination",
                            clickable: true,
                        }
                    });
                }
            }, 800);

            $("#gondole_derniers_articles_vus #productVisitedSwiper .productSwiper.swiper-container").each(function () {
                var $thisCartswiper = $(this);

                if ($thisCartswiper[0].swiper) {
                    $thisCartswiper[0].swiper.destroy(true, true);
                }

                selectionoffertePopup = new Swiper(this, {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    navigation: {
                        nextEl: $thisCartswiper.closest('.item').find('.rollover_rayonsNext')[0],
                        prevEl: $thisCartswiper.closest('.item').find('.rollover_rayonsPrev')[0]
                    }
                });
            });

        }, 200);
    }

    if (swiperContainerBrowseRecentlyWide) {
        setTimeout(function () {
            // Destroy existing Swiper if it exists
            if (cartRecentVisitSwiper) {
                cartRecentVisitSwiper.destroy(true, true);
            }

            var slides = document.querySelectorAll('.emptyBasketSwiper > .swiper-wrapper > .item.swiper-slide');
            if (slides.length > 4) {
                cartRecentVisitSwiper = new Swiper('#gondole_derniers_articles_vus .emptyBasketSwiper', {
                    slidesPerView: 4,
                    spaceBetween: 15,
                    navigation: {
                        nextEl: '#gondole_derniers_articles_vus .swiper-button-next',
                        prevEl: '#gondole_derniers_articles_vus .swiper-button-prev',
                    },
                    pagination: {
                        el: ".panier-swiper-pagination",
                        clickable: true,
                    }
                });
            }

            $("#gondole_derniers_articles_vus #productVisitedSwiper .productSwiper.swiper-container").each(function () {
                var $thisCartswiper = $(this);

                if ($thisCartswiper[0].swiper) {
                    $thisCartswiper[0].swiper.destroy(true, true);
                }

                selectionoffertePopup = new Swiper(this, {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    navigation: {
                        nextEl: $thisCartswiper.closest('.item').find('.rollover_rayonsNext')[0],
                        prevEl: $thisCartswiper.closest('.item').find('.rollover_rayonsPrev')[0]
                    }
                });
            });
        }, 200);
    }
    stopExistingSwiperAutoplay();
}

function updateCartElements() {
    cartChildren = $('.cart.step_1 .cart_main_table.basket').children().length;

    setTimeout( () => {            
        if (cartChildren <= 1) {
            $('.cart.step_1 #main_cart_wrapper').addClass('emptyBasket');
            $('.cart.step_1 #productVisitedSwiper').addClass('emptyBasketSwiper');
        } else {
            $('.cart.step_1 #main_cart_wrapper').removeClass('emptyBasket');
            $('.cart.step_1 #productVisitedSwiper').removeClass('emptyBasketSwiper');
        }
    }, 100);
}

updateCartElements();

function display_lightbox_specifique() {

    var html = ajax_file(path_relative_root + 'ajax_lightbox_produit_specifique.php');
    if (html) {
        var $lighbox = $('#lightbox_achat_express');
        var $shad = $('#shad');
        $lighbox
            .html('<div style="position: relative">' + html + '</div>')
            .appendTo('body')
            .show();
        $shad
            .show()
            .unbind('click.achat_express')
            .bind('click.achat_express', function () {
                $("#alertbox").remove();
            });
    }
}

function showLightBoxSelectionOfferte() {
    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_lightbox_selection_offerte.php',
        success: function (response) {
            var $shad = $('#shad');
            if ($('#selectionOffertePopup').length == 0) {
                $("body").append(response);
            }

            var id_produit_offert_unique = $('.ligne_produit_offert').length == 1 ? $('.ligne_produit_offert').data('idproduit') : 0;

            if (response.trim() != '') {
                // Si le nombre de selection égale 1 on ajoute le produit directement dans le panier, sinon on affiche la LightBox avec la selection
                if (typeof (nb_selection_offert) != 'undefined' && nb_selection_offert == 1 && $('.ligne_produit_offert').length == 1 && $("#qteProd_" + id_produit_offert_unique).val() > 0) {
                    $('.ligne_produit_offert').addClass('seleted');
                    ajoutLightBoxSelectionOfferte();
                } else {
                    show_popup('selectionOffertePopup');
                    //$(".productsWrapper").lionbars();

                    /* INITIALISER SWIPER POP UP SELECTION OFFERTE */

                    if ($("#selectionOffertePopup .swiper-container").length > 0) {

                        var selectionofferte_popup = new Swiper("#selectionOffertePopup .swiper-container", {
                            slidesPerView: 2,
                            spaceBetween: 20,
                            navigation: {
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            }
                        })
                    }
                }
                $shad.click(function () {
                    closeMultiShad();
                });
            }
        }
    });
}

function verif_mail(elementID, fieldname, is_spe) {

    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var mail = new RegExp("^[a-z0-9_-]+([.]?[a-z0-9_-]{1,})*@([a-z0-9_-]{2,}[.])+[a-z]{2,4}$", "gi");

    if (mail.exec(objElement.value) == null) {
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;

    }
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}


// Function who reshape the tel number from +33612345678 to 06 12 34 56 78

function reformateTel() {
    if ($('.satellite_store_details').length) {
        var div = document.querySelector('.contact_itm.tel');
        var numero = '0' + div.textContent.slice(3);
        div.textContent = numero.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
    }
}

reformateTel();

function lightboxEventListenner() {
    document.body.addEventListener('click', function (event) {
        if (event.target.classList.contains('size_icon')) {
            document.querySelectorAll('.size_selected').forEach(function (el) {
                el.classList.remove('size_selected');
            });
            event.target.closest('.size').classList.add('size_selected');
        }
    });
}

// /*
//  * Allow the sending of the email that confirms the registration of their email, to alert the person when the product will be available.
//  */
function sendMailAlertStock(type_alert, form, itm_rr_id) {

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id = form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() !== undefined && $('#is_achat_express_v2').val() == 1;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    var $email = $('#mail_alerte_stock' + elem).val(),
        $produit_id = $('#produit_principal' + elem).val(),
        $taille_id = $("#tailleProd" + elem).val(),
        $couleur_id = $("#couleurProd" + elem).val(),
        $type_alert = type_alert,
        $verif_mail = checkForm('mail', document.getElementById('mail_alerte_stock' + elem), 'login_email');

    if ($email == '' || !$verif_mail) {

        $('#mail_alerte_stock' + elem).addClass('inputErr');
    } else {

        $.ajax({
            type: 'post',
            url: path_relative_root + 'ajax_mail_alert_stock.php',
            data: {
                email: $email,
                produit_id: $produit_id,
                taille_id: $taille_id,
                couleur_id: $couleur_id,
                type_alert: $type_alert
            },
            success: function (response) {
                $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
            },
            fail: function () {
                $('.bloc_add_alert' + elem + ' .alert_stock').show();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}

function checkBtnEresaShop() {
    $("#btn_resa_shop").on({
        mouseenter: function () {
            $("#bloc_add_basket").hasClass("inactiv") ? $("#sizeerror").show() : null;
        },
        mouseleave: function () {
            $("#sizeerror").hide();
        }
    });
}

function initSwiperSlide() {

    if ($('.product_page').length) {
        // Initialisation swiper association products
        initializeSwiper('#assoc_product_slider', {
            slidesPerView: 4,
            spaceBetween: 15,
            allowSlidePrev: true,
            allowSlideNext: true,
            loop: true,
            navigation: {
                nextEl: '.assoc-button-next',
                prevEl: '.assoc-button-prev',
            },
            pagination: {
                el: '.assoc_slider_pagination',
                clickable: true,
            },
        });

        // Initialisation swiper association theme
        initializeSwiper('#assoc_theme_slider', {
            slidesPerView: 4,
            spaceBetween: 15,
            allowSlidePrev: true,
            allowSlideNext: true,
            loop: true,
            navigation: {
                nextEl: '.assoc-button-next_theme',
                prevEl: '.assoc-button-prev_theme',
            },
            pagination: {
                el: '.assoc_theme_slider_pagination',
                clickable: true,
            },
        });
    }

    // Load only if the page has the class '.slider-cover-module'
    if ($('.cms-page-module.slider-cover-module').length) {
        // Initialisation swiper module 1 home
        initializeSwiper('.slider-cover-module .swiper-container', {
            pagination: {
                el: '.cover-pagination',
            },
            autoplay: {
                delay: 5000,
            },
        });
    }

    // Load only if the page has the class '.tg-module'
    if ($('.cms-page-module.tg-module').length) {
        // Initialisation swiper module 9 home
        initializeSwiper('.cms-page-module.tg-module .main-swiper', {
            slidesPerView: 4,
            spaceBetween: 15,
            allowSlidePrev: true,
            allowSlideNext: true,
            loop: true,
            navigation: {
                nextEl: '.tg-module-next',
                prevEl: '.tg-module-prev',
            },
            pagination: {
                el: '.tg-module-pagination',
                clickable: true,
            },
        });
    }
}

if ($('.homepage').length) {
    window.addEventListener('load', function() {
        $('.home_module.cover_module img').removeClass('pre-load');
    });
}

function openAlerteStock(id) {
    $('.bloc_add_alert_' + id).show();
    $('form a#warn_available_' + id).addClass('none-important');
}

function closeAlerteStockWishlist(elem) {
    $('.bloc_add_alert_' + elem).hide();
    $('form a#warn_available_' + elem).removeClass('none-important');
}

/*Move the Add to basket button to the .cart_product_line div on cart page*/
function updateCartUI() {
    if ($('body').hasClass('cart')) {
        $('.cart_product_line .cart_product_desc .wrapper_cart_product_desc form:not(.cart_item_actions_form').each(function () {
            $(this).closest('.cart_product_line').append($(this));
        });

        $('.cart_product_line .cart_product_desc .wrapper_cart_product_desc .cart_product_modify a.wishToBasket').each(function () {
            $(this).closest('.cart_product_line').append($(this));
        });
    }
}

// Add product to basket from wishlist (connected)
function moveProductFromWishlistToCart(elemBtn = false, selectedProducts = {}) {
    let datas = {};

    if (elemBtn) {
        var prodId = $(elemBtn).attr("data-produit-id");
        var colorId = $(elemBtn).attr("data-color-id");
        var sizeId = $(elemBtn).attr("data-size-id");

        datas = {
            data: $(elemBtn).attr("data-id"),
        };

        if (prodId !== undefined) {
            datas.productId = prodId;
        }

        if (colorId !== undefined) {
            datas.colorId = colorId;
        }

        if (sizeId !== undefined) {
            datas.sizeId = sizeId;
        }
    } else {
        datas = selectedProducts;
    }

    $(elemBtn).next().addClass('loading');
    $(elemBtn).attr('onclick', 'return false;');

    $.post(path_relative_root + create_link("ajax_add_panier_wishlist"), datas, function(resp) {
        if (resp == "ok") {
            document.location.href = path_relative_root + create_link('order_basket');
        } else {
            $(elemBtn).next().removeClass('loading');
            $(elemBtn).attr('onclick', 'moveProductFromWishlistToCart(this);');
        }
    });
}

$(document).on("click","#btn_top_add_wishlist", function (e) {
    e.preventDefault();

    let datas = [];
    let selectedTopProducts = {};

    $(".remove_prod.wishlist_remove").each(function() {

        datas.push({
            productId: $(this).attr("data-produit-id"),
            colorId: $(this).attr("data-color-id"),
            sizeId: $(this).attr("data-size-id"),
        });
    });

    selectedTopProducts.selectedProducts = JSON.stringify(datas);
    moveProductFromWishlistToCart(false, selectedTopProducts);
});

$(document).on("click", ".cart .draggableElem .wishToBasket, .cart .cart_product_modify .basketToWishlist", function () {
    // Perform any actions before refreshing the page here

    setTimeout(function () {
        location.reload(); // Refresh the page after a delay
    }, 500); // Adjust the delay in milliseconds as needed
});
